// src/services/api.js
import { auth, db } from '../config/firebase';
import { 
  getFirestore,
  collection as firestoreCollection,
  addDoc, 
  serverTimestamp, 
  doc, 
  setDoc,
  getDocs,
  writeBatch,
  Timestamp,
  getDoc,
  query,
  where
} from 'firebase/firestore';
import { formatName } from '../utils/formatters';
import { toast } from 'react-hot-toast';

export const saveSearchHistory = async (userId, searchData, searchResults) => {
  try {
    await addDoc(firestoreCollection(db, 'users', userId, 'searches'), {
      timestamp: serverTimestamp(),
      searchType: searchData.searchType,
      individualName: searchData.individualName ? formatName(searchData.individualName) : null,
      companyName: searchData.companyName ? formatName(searchData.companyName) : null,
      organization: searchData.organization ? formatName(searchData.organization) : null,
      designation: searchData.designation ? formatName(searchData.designation) : null,
      dob: searchData.dob || null,  // Make sure DOB is included
      gender: searchData.gender || null,
      country: searchData.country,
      matchThreshold: searchData.matchThreshold,
      alerts: searchResults.filter(r => r.properties?.topics?.length > 0).length,
      results: searchResults
    });
  } catch (error) {
    console.error('Error saving search history:', error);
  }
};

export const API_URL = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
  ? process.env.REACT_APP_EMULATOR_BACKEND_URL
  : process.env.REACT_APP_CLOUD_FUNCTION_URL;

// Add request deduplication
let currentRequest = null;

// Get the current user's ID token
const getIdToken = async () => {
  const user = auth.currentUser;
  
  if (!user) {
    throw new Error('User not authenticated');
  }
  
  try {
    const token = await user.getIdToken();
    return token;
  } catch (error) {
    console.error('Error getting ID token:', error);
    throw new Error('Failed to get authentication token');
  }
};

// Main search API function - single endpoint
export const searchApi = async (searchData) => {
  try {
    // If there's already a request in progress, cancel it
    if (currentRequest) {
      console.log('Canceling duplicate request');
      return currentRequest;
    }

    // Get the authentication token
    const idToken = await getIdToken();

    // Create the new request
    currentRequest = (async () => {
      try {
        const response = await fetch(`${API_URL}/search`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
          body: JSON.stringify(searchData)
        });
        
        if (response.status === 429) {
          toast.error('Daily limit reached. Please try again tomorrow.');
          throw new Error('Daily limit reached');
        }

        if (!response.ok) {
          const errorText = await response.text();
          console.error('API Error response:', errorText);
          throw new Error(errorText || 'API request failed');
        }

        const responseData = await response.json();

        // Save search history after successful search
        if (auth.currentUser) {
          await saveSearchHistory(auth.currentUser.uid, searchData, responseData.results);
        }

        return responseData;
      } finally {
        // Clear the current request when done
        currentRequest = null;
      }
    })();

    return await currentRequest;
  } catch (error) {
    console.error('API call error:', error);
    if (error.message !== 'Daily limit reached') {
      toast.error('Sorry, something went wrong. Please try again later.');
    }
    throw error;
  }
};

export const saveMonitoredEntity = async (userId, data) => {
  try {
    const token = await auth.currentUser.getIdToken();
    const response = await fetch(`${API_URL}/api/save-monitored-entity`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to save monitored entity');
    }

    return response.json();
  } catch (error) {
    console.error('Error saving monitored entity:', error);
    throw error;
  }
};

export const checkApiLimits = async (userId) => {
  try {
    const token = await auth.currentUser.getIdToken(true);
    const response = await fetch(`${API_URL}/api/user-stats`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to fetch user stats');
    }

    const data = await response.json();
    
    // Return a consistent structure that matches what SearchResults.js expects
    return {
      isLimited: data.isLimited,
      searchesRemaining: data.searchesRemaining || {
        daily: data.limits[data.isSubscribed ? 'paid' : 'free'].DAILY_SEARCHES - data.currentUsage.dailySearches,
        total: data.isSubscribed ? null : data.limits.free.TOTAL_SEARCHES - data.currentUsage.totalSearches
      },
      monitoringRemaining: data.monitoringRemaining || 
        (data.limits[data.isSubscribed ? 'paid' : 'free'].MONITORED_ENTITIES - data.currentUsage.monitoredEntities),
      isSubscribed: data.isSubscribed
    };
  } catch (error) {
    console.error('Error checking API limits:', error);
    throw error;
  }
};