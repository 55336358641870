const NotificationBubble = ({ count }) => {
    // Don't render if count is 0 or undefined
    if (!count) return null;
    
    // Format large numbers (e.g. 1000 -> 999+)
    const displayCount = count > 999 ? '999+' : count.toString();
    
    return (
      <span className={`
        absolute -top-1 -right-1
        min-w-4 h-4
        ${displayCount.length > 1 ? 'px-1.5' : 'px-1'}
        flex items-center justify-center
        bg-red-500 text-white
        text-xs font-medium
        rounded-full
        animate-fade-in
      `}>
        {displayCount}
      </span>
    );
  };
  
  export default NotificationBubble;