// In UserMenu.js
import { useState, useRef, useEffect } from 'react';
import { User, LogOut, HelpCircle, Crown, CreditCard, X } from 'lucide-react';
import { signOut } from 'firebase/auth';
import { logEvent } from 'firebase/analytics';
import { auth, analytics } from '../config/firebase';
import useSubscription from './useSubscription';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  AlertDialogCancel
} from '../components/ui/alert-dialog';
import SubscriptionPlansModal from './SubscriptionPlansModal';

const UserMenu = ({ user, onSignOut }) => {
    // State declarations
    const [isOpen, setIsOpen] = useState(false);
    const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);
    const menuRef = useRef(null);
    
    // Get subscription data and handlers from the hook
    const { 
      stats, 
      handleSubscribe, 
      handleCancelSubscription, 
      handleReactivateSubscription 
    } = useSubscription();
  
    // Close menu when clicking outside
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);
  
    const handleSignOut = async () => {
      try {
        await signOut(auth);
        logEvent(analytics, 'sign_out', { success: true });
        onSignOut();
      } catch (error) {
        logEvent(analytics, 'sign_out_error', {
          error_code: error.code,
          error_message: error.message
        });
        console.error('Error signing out:', error);
      }
    };
  
    const handleHelp = () => {
      window.location.href = 'mailto:support@beam.enterprises';
    };
  
    const handleSubscribeClick = async () => {
      setSubscriptionDialogOpen(false); // Close the modal first
      await handleSubscribe(); // Then initiate subscription
    };
  
    return (
      <div className="relative" ref={menuRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          aria-label="User menu"
        >
          <User className="w-5 h-5 text-gray-600" />
        </button>
  
        {isOpen && (
          <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
            <div className="py-1">
              {/* User info section */}
              <div className="px-4 py-2 text-sm text-gray-700 border-b border-gray-200">
                <div className="font-medium truncate mb-2">{user.email}</div>
                <div className="space-y-1">
                  <div className="flex justify-between text-xs">
                    <span className="text-gray-500">Searches Used</span>
                    <span className="text-gray-900 font-medium">
                      {stats?.currentUsage?.totalSearches || 0}/
                      {stats?.isSubscribed 
                        ? stats?.limits?.paid?.MONTHLY_SEARCHES
                        : stats?.limits?.free?.TOTAL_SEARCHES}
                    </span>
                  </div>
                  <div className="w-full bg-gray-100 rounded-full h-1.5">
                    <div 
                      className={`h-1.5 rounded-full ${
                        stats?.isSubscribed ? 'bg-green-500' : 'bg-blue-500'
                      }`}
                      style={{ 
                        width: `${Math.min(100, ((stats?.currentUsage?.totalSearches || 0) / 
                          (stats?.isSubscribed 
                            ? stats?.limits?.paid?.MONTHLY_SEARCHES
                            : stats?.limits?.free?.TOTAL_SEARCHES)) * 100)}%` 
                      }}
                    />
                  </div>
                </div>
              </div>
              
              {/* Mobile subscription button */}
              <button
                onClick={() => {
                  setSubscriptionDialogOpen(true);
                  setIsOpen(false);
                }}
                className={`w-full flex items-center px-4 py-2 text-sm font-medium hover:bg-gray-100 focus:outline-none focus:bg-gray-100 md:hidden ${
                  stats?.isSubscribed ? 'text-green-600' : 'text-[#2B82DE]'
                }`}
              >
                {stats?.isSubscribed ? (
                  <>
                    <Crown className="mr-2 h-4 w-4 text-green-600" />
                    Pro Subscription
                  </>
                ) : (
                  <>
                    <CreditCard className="mr-2 h-4 w-4 text-[#2B82DE]" />
                    Upgrade to Pro
                  </>
                )}
              </button>
              
              {/* Help button */}
              <button
                onClick={handleHelp}
                className="w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
              >
                <HelpCircle className="mr-2 h-4 w-4" />
                Help
              </button>

              {/* Sign out button */}
              <button
                onClick={handleSignOut}
                className="w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
              >
                <LogOut className="mr-2 h-4 w-4" />
                Sign out
              </button>
            </div>
          </div>
        )}

        {/* Subscription dialog */}
        <AlertDialog open={subscriptionDialogOpen} onOpenChange={setSubscriptionDialogOpen}>
          {subscriptionDialogOpen && (
            <SubscriptionPlansModal 
              onClose={() => setSubscriptionDialogOpen(false)}
              stats={stats}
              onSubscribe={handleSubscribeClick}
              onCancel={handleCancelSubscription}
              onReactivate={handleReactivateSubscription}
            />
          )}
        </AlertDialog>
      </div>
    );
};

export default UserMenu;