import React from 'react';
import { Check, X, Newspaper, User, Users, Cloud, Clock, LineChart, Search, Lock, UserCog, Bell, FileText } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { formatDate } from '../utils/formatters';
import { AlertDialogContent, AlertDialogCancel } from '../components/ui/alert-dialog';

const isUserSubscribed = (stats) => {
  return stats?.isSubscribed === true;
};

const PlanCard = ({ plan, price, features, isPopular, isEnterprise, onSelect, isCurrentPlan, onCancel, onReactivate, subscriptionData, stats }) => (
  <div className={`relative flex flex-col p-6 bg-white rounded-xl border ${
    ((isPopular && isUserSubscribed(stats) === false) || (isUserSubscribed(stats) && isEnterprise))
      ? 'shadow-lg border-primary-600'
      : 'shadow-sm hover:shadow-md'
  }`}>
    {((isCurrentPlan && isUserSubscribed(stats)) || (!isUserSubscribed(stats) && plan === "Free Trial")) && (
      <div className="absolute -top-3 left-1/2 -translate-x-1/2">
        <span className="px-3 py-1 text-xs font-medium text-green-600 bg-green-50 rounded-full">
          Current Plan
        </span>
      </div>
    )}
    
    {isPopular && !isCurrentPlan && isUserSubscribed(stats) === false && (
      <div className="absolute -top-3 left-1/2 -translate-x-1/2">
        <span className="px-3 py-1 text-xs font-medium text-primary-600 bg-primary-50 rounded-full">
          Popular
        </span>
      </div>
    )}
    
    {isEnterprise && isUserSubscribed(stats) && (
      <div className="absolute -top-3 left-1/2 -translate-x-1/2">
        <span className="px-3 py-1 text-xs font-medium text-primary-600 bg-primary-50 rounded-full">
          Upgrade
        </span>
      </div>
    )}
    
    <div className="mb-4">
      <h3 className="text-xl font-semibold text-gray-900">{plan}</h3>
      {isEnterprise ? (
        <div className="mt-2 flex items-baseline">
          <span className="text-3xl font-bold text-gray-900">Custom</span>
          {/* <span className="text-sm text-gray-500 ml-1">off Pro</span> */}
        </div>
      ) : (
        <div className="mt-2 flex items-baseline">
          <span className="text-3xl font-bold text-gray-900">${price}</span>
          <span className="text-sm text-gray-500 ml-1">/month</span>
        </div>
      )}
    </div>

    <div className="flex-grow">
      <ul className="space-y-4">
        {features.map((feature, index) => (
          <li key={index} className="flex items-start">
            <div className="flex-shrink-0 w-8 h-8 bg-primary-50 rounded-lg flex items-center justify-center">
              {feature.icon}
            </div>
            <div className="ml-3">
              <div className="flex items-center gap-2">
                <p className="text-sm font-medium text-gray-900">{feature.title}</p>
                {feature.comingSoon && (
                  <span className="px-2 py-0.5 text-xs font-medium text-yellow-600 bg-yellow-50 rounded-full">
                    Coming Soon
                  </span>
                )}
              </div>
              <p className="text-sm text-gray-500">{feature.description}</p>
            </div>
          </li>
        ))}
      </ul>

      {/* Show subscription status details for current plan */}
      {isCurrentPlan && subscriptionData && (
        <div className="mt-6 space-y-4">
          {/* Empty div maintained for consistent spacing */}
        </div>
      )}
    </div>

    {/* Button states based on plan status */}
    {!isCurrentPlan ? (
      <button
        onClick={
          isEnterprise 
            ? () => window.location.href = 'https://calendly.com/agniiyer/30min'
            : plan === "Free Trial"
              ? null
              : onSelect
        }
        className={`mt-6 w-full py-3 px-4 rounded-lg font-medium transition-colors ${
          plan === "Free Trial"
            ? !isUserSubscribed(stats) && (!stats?.currentUsage?.totalSearches || stats.currentUsage.totalSearches < stats?.limits?.free?.TOTAL_SEARCHES)
              ? 'bg-green-100 text-green-700'
              : 'bg-gray-100 text-gray-900'
            : isEnterprise 
              ? isUserSubscribed(stats) === false
                ? 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                : 'bg-[#2B82DE] text-white hover:bg-[#2B82DE]/90'
              : isUserSubscribed(stats) === false
                ? 'bg-[#2B82DE] text-white hover:bg-[#2B82DE]/90'
                : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
        }`}
        disabled={plan === "Free Trial"}
      >
        {isEnterprise 
          ? 'Book Demo' 
          : plan === "Free Trial"
            ? !isUserSubscribed(stats) && (!stats?.currentUsage?.totalSearches || stats.currentUsage.totalSearches < stats?.limits?.free?.TOTAL_SEARCHES)
              ? 'Active'
              : 'Complete'
            : `Get ${plan}`
        }
      </button>
    ) : (
      <button
        onClick={stats?.cancellationRequested ? onReactivate : onCancel}
        className="mt-6 w-full py-3 px-4 rounded-lg font-medium transition-colors border-2 border-red-600 text-red-600 bg-white hover:bg-red-50"
      >
        {stats?.cancellationRequested ? 'Restore Subscription' : 'Cancel Subscription'}
      </button>
    )}
  </div>
);

const SubscriptionPlansModal = ({ onClose, stats, onSubscribe, onCancel, onReactivate }) => {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);

    const handler = (e) => setIsMobile(e.matches);
    mediaQuery.addListener(handler);
    return () => mediaQuery.removeListener(handler);
  }, []);

  const handleSubscribeClick = () => {
    if (isMobile) {
      onClose?.();
    }
    onSubscribe();
  };

  const plans = [
    {
      name: "Free Trial",
      price: 0,
      features: [
        {
          icon: <Search className="w-4 h-4 text-primary-600" />,
          title: "5 searches",
          description: "One-time search limit"
        },
        {
          icon: <Newspaper className="w-4 h-4 text-primary-600" />,
          title: "Global Sanctions and Media",
          description: "Always up to date"
        },
        {
          icon: <User className="w-4 h-4 text-primary-600" />,
          title: "Single User",
          description: "Basic access for individuals"
        },
        {
          icon: <Cloud className="w-4 h-4 text-primary-600" />,
          title: "Cloud Access",
          description: "Secure cloud deployment"
        }
      ]
    },
    {
      name: "Pro",
      price: 500,
      isPopular: true,
      features: [
        {
          icon: <Search className="w-4 h-4 text-primary-600" />,
          title: "1000 searches",
          description: "Renewed every month"
        },
        {
          icon: <Bell className="w-4 h-4 text-primary-600" />,
          title: "Entity Monitoring",
          description: "Automated intelligent alerts"
        },
        {
          icon: <Users className="w-4 h-4 text-primary-600" />,
          title: "Team Access",
          description: "Collaboration features included",
          comingSoon: true
        },
        {
          icon: <Clock className="w-4 h-4 text-primary-600" />,
          title: "24/7 Support",
          description: "Priority customer support"
        }
      ]
    },
    {
      name: "Enterprise",
      isEnterprise: true,
      features: [
        {
          icon: <Search className="w-4 h-4 text-primary-600" />,
          title: "Bespoke search limit",
          description: "Lower cost per search"
        },
        {
          icon: <LineChart className="w-4 h-4 text-primary-600" />,
          title: "Bullseye Integration",
          description: "Real time transaction monitoring"
        },
        {
          icon: <Lock className="w-4 h-4 text-primary-600" />,
          title: "On-Premises",
          description: "Private deployment options",
          comingSoon: true
        },
        {
          icon: <FileText className="w-4 h-4 text-primary-600" />,
          title: "Reporting Copilot",
          description: "Drafts SARs, CTRs and more",
          comingSoon: true
        }
      ]
    }
  ];

  return (
    <AlertDialogContent className={`
      max-w-6xl overflow-auto p-0
      md:max-h-[90vh] md:mx-auto
      ${isMobile ? 'max-h-[100dvh] w-full h-full m-0 rounded-none' : ''}
    `}>
      <div className="bg-gray-50 rounded-xl w-full relative">
        {/* Header */}
        <div className="flex items-center justify-between p-6 border-b bg-white sticky top-0 z-50">
          <div>
            <h2 className="text-2xl font-bold text-gray-900">
              {stats?.isSubscribed ? 'Pro Subscription' : 'Upgrade your plan'}
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              {stats?.isSubscribed 
                ? 'Manage your subscription' 
                : 'Select the plan that best fits your needs'}
            </p>
          </div>
          <AlertDialogCancel 
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            onClick={onClose}
          >
            <X className="w-5 h-5 text-gray-500" />
          </AlertDialogCancel>
        </div>

        {/* Plans Grid */}
        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {plans.map((plan) => (
              <PlanCard
                key={plan.name}
                plan={plan.name}
                price={plan.price}
                features={plan.features}
                isPopular={plan.isPopular}
                isEnterprise={plan.isEnterprise}
                isCurrentPlan={stats?.isSubscribed && plan.name === "Pro"}
                subscriptionData={stats?.isSubscribed ? stats : null}
                stats={stats}
                onSelect={
                  plan.isEnterprise 
                    ? () => window.location.href = 'https://calendly.com/agniiyer/30min'
                    : () => handleSubscribeClick()
                }
                onCancel={onCancel}
                onReactivate={onReactivate}
              />
            ))}
          </div>
        </div>
      </div>
    </AlertDialogContent>
  );
};

export default SubscriptionPlansModal;